/* ------------------------------------------ Functions ------------------------------ */

// sets show/hide based on arguments
function ifCheckedHide(idCheckedInput, idHideShow) {
	if ($( "#" + idCheckedInput ).is(":checked")) $( "#" + idHideShow ).hide();
	else $( "#" + idHideShow ).show();
}


let comboBoxes = [];	// to be used to fill with each dropdown item
let previousSelectedCustomer;
let previousSelectedProject;
let invalidProject;		// tom-select option object for invalid/archived project 

/** 
 * Searchable combobox setup (called by document ready), finds "searchable-input" and creates a searchable dropdown with Tom Select 
 * 
 */
function initComboBox() {
	document.querySelectorAll('.searchable-input').forEach((element)=>{
		// If we already have List Items we need to remove them from the list of comboBoxes and add again 
		if(element.id == "CustomerID"){
			previousSelectedCustomer = $("#CustomerID :selected").val();
			if(comboBoxes.find(listItem => listItem.id == "CustomerID")){
				comboBoxes = comboBoxes.filter(function(item){return item.id != "CustomerID"});
			}
		}
		if(element.id == "ProjectID"){
			previousSelectedProject = $("#ProjectID :selected").val();
			if(comboBoxes.find(listItem => listItem.id == "ProjectID")){
				comboBoxes = comboBoxes.filter(function(item){return item.id != "ProjectID"});
			}
		}
		if(comboBoxes.find(listItem => listItem.id == "AccountCodeID")){
			comboBoxes = comboBoxes.filter(function(item){return item.id != "AccountCodeID"});
		}
		if(comboBoxes.find(listItem => listItem.id == "EventID")){
			comboBoxes = comboBoxes.filter(function(item){return item.id != "EventID"});
		}
		if(comboBoxes.find(listItem => listItem.id == "VendorID")){
			comboBoxes = comboBoxes.filter(function(item){return item.id != "VendorID"});
		}

		this.selectedItem = "";	// item selected when the page loads, why didn't tom-select include this?
		let settings = {
			maxOptions: null,
			maxItems: 1,
			closeAfterSelect: true,
			allowEmptyOption: true,
			plugins: ['dropdown_input'],
			onChange: function(){
				// for Customer and Project only
				if(element.id == "CustomerID" || element.id == "ProjectID"){
					$("#inlineProjectDescription").remove();
					$("#projectDialogLabel").remove();

					// if we have a description add to the page 
					if(this.options && this.options[this.items[0]] && this.options[this.items[0]].description){

						let description = decodeURIComponent(this.options[this.items[0]].description);
						$("#projectDescription").html("<p>" + description + "</p>");

						$(".projectDesc").append("<div id='inlineProjectDescription'>" + description + "</div>");	// description (with ellipses if needed)

						if(description.length > 30){
							// add the little pop-up with full description for longer text 
							$(".projectDesc").append("<div><a href='javascript:launchProjectDialog()' id='projectDialogLabel'>" + getTranslation("JavaScript.Link.MoreEllipsis") + "</a></div>");
						}
					}
					else {
						// no description, so say "None"
						$(".projectDesc").append("<div id='inlineProjectDescription'>" + getTranslation("Content.Label.None") + "</div>");
					}

					// we need to set these static values for changeBillRate() to function properly 
					if(element.id == "ProjectID"){
						invalidProject = this.options[$("#ProjectID_invalidOption").val()];

						if(this.options && this.options[this.items[0]]){
							$("#currentBillRate").val(this.options[this.items[0]].billrate);
							$("#ProjectID").val(this.options[this.items[0]].value);
							changeBillRate();	// lives in page-project-edit.js 
						}
						
					}
				}

				if($("#"+element.id+"_invalidOption").val()){
					if(this.options[this.items[0]] && (this.options[this.items[0]].value == $("#"+element.id+"_invalidOption").val())){
						$("#"+element.id+"_invalid").show();
					}
					else { 
						$("#"+element.id+"_invalid").hide();
					}
				}
			},
			onBlur: function(){
				if(this.items.length < 1){
					// if the user leaves the search blank, set item selected when the page loaded 
					let selected = this.options[this.selectedItem];
					if(selected){
						this.setValue(selected.value, false);
					}
				}
			},
			onInitialize: function(){
				this.selectedItem = this.items[0];
			}
		};

		let hasDropdown = comboBoxes.find(tomItem => tomItem.id == element.id);
		// make sure the dropdown isn't already on the page. All dropdowns must have an id or name attribute!
		if((element.id || element.name) && !hasDropdown){
			let tSelect = new TomSelect(element,settings);
			comboBoxes.push({ id: element.id, tomList: tSelect });
		}
	});
}

/**
 *	Update a given combobox based on previously selected item (so far just Customer -> Project)
 * 
 * 	@param json {Object} - list/array of select items to add to an existing combobox 
 * 	@param previousSelectedID {String} - ID of item (Project only for now) that was selected before getting new item list 
 *	@param id {String} - we don't need this much, but can use it if we go beyond just customer/project 
 */
function updateComboBox(json, previousSelectedValue, id){

	comboBoxes.forEach(function(item, index){
		// So far just "ProjectID"
		let previousSelectedInArray = json.filter((json) => json.PROJECTID == previousSelectedValue);	// see if the previous Project is in the new list 

		if(id == item.id){
			if(json.length > 0){
				if(previousSelectedInArray.length <= 0){
					item.tomList.clear(false);		// clears selected items, must be called before clearOptions() for some reason 
				}
				item.tomList.clearOptions();	// if the new list has anything, clean the old list out 
			}
			
			// loop through the new options and add to the list 
			json.forEach(function(jsonItem, jsonIndex){
				let projectDescription = (jsonItem.PROJECTDESCRIPTION != "") ? jsonItem.PROJECTDESCRIPTION : getTranslation("Content.Label.None");
				let option = { value: jsonItem.PROJECTID, text: jsonItem.PROJECTNAME, description: projectDescription, minuteinc: jsonItem.MINIMUMTIMEINCREMENT, billrate: jsonItem.DEFAULTUSERBILLRATE };
				item.tomList.addOption(option);				
			});

			
			if(invalidProject){
				// make sure to add the invalid project back into the list 
				item.tomList.addOption(invalidProject);
				
				if(previousSelectedInArray.length == 0){
					item.tomList.setValue(invalidProject.value, false); // ??????
				}
			}
			else if(previousSelectedInArray.length <= 0){
				item.tomList.setValue(1, false);	// if the previous item is not in the new list, fall back to Unassigned/default
			}
			
		}
	});
}
